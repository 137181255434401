import React from 'react';
// import { useNavigate } from "react-router-dom";


class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.registrant ;

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleLevelChange = this.handleLevelChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleNameChange(event) {
    this.setState({name: event.target.value});
  }

  handleLevelChange(event) {
    this.setState({level: event.target.value});
  }

  handleSubmit(event) {
		this.props.register(this.state);
    event.preventDefault();

    this.props.gotoQuiz();
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>

	      <div class="container text-start">

	        <div class="row">
	          <div class="col-2">
	            Name
	          </div>
	          <div class="col-8">
							<input type="text" class="form-control" value={this.state.name} onChange={this.handleNameChange} />
	          </div>
	        </div>

	        <br/>

	        <div class="row">
	          <div class="col-2">
	            Level
	          </div>
	          <div class="col">

	            <div class="col-sm-3 form-check form-check-inline">
	              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="Easy" checked={this.state.level === "Easy"} onChange={this.handleLevelChange} />
	              <label class="form-check-label" for="inlineRadio1"> Easy <small>(Age 5 - 9)</small> </label>
	            </div>

	            <div class="col-sm-3 form-check form-check-inline">
	              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="Normal" checked={this.state.level === "Normal"} onChange={this.handleLevelChange} />
	              <label class="form-check-label" for="inlineRadio2"> Normal <small>(Age 10 - 15)</small>  </label>
	            </div>

	            <div class="col-sm-3 form-check form-check-inline">
	              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="Hard" checked={this.state.level === "Hard"} onChange={this.handleLevelChange} />
	              <label class="form-check-label" for="inlineRadio3"> Hard  <small>(Age 16+)</small> </label>
	            </div>

	          </div>
	        </div>

	        <br/>

	        <div class="row text-center">
	          <div class="col-9">
	            <button type="submit" class="btn btn-success">Submit</button>
	          </div>
	        </div>


	      </div>

      </form>
    );
  }
}


export default RegisterForm;
