import React from 'react';

function Quiz(props) {

  const timeLimit = 5;
  const maxQ = 10;

  const flagsArray = [
    { country: "Taliban", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_the_Taliban.svg/540px-Flag_of_the_Taliban.svg.png" },
    { country: "Afghanistan", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/Flag_of_Afghanistan_%282013%E2%80%932021%29.svg/540px-Flag_of_Afghanistan_%282013%E2%80%932021%29.svg.png" },
    { country: "Albania", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/Flag_of_Albania.svg/540px-Flag_of_Albania.svg.png" },
    { country: "Algeria", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/Flag_of_Algeria.svg/540px-Flag_of_Algeria.svg.png" },
    { country: "Argentina", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Flag_of_Argentina.svg/540px-Flag_of_Argentina.svg.png" },
    { country: "Austria", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Flag_of_Austria.svg/540px-Flag_of_Austria.svg.png" },
    { country: "Australia", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Flag_of_Australia_%28converted%29.svg/540px-Flag_of_Australia_%28converted%29.svg.png" },
    { country: "Bhutan", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Flag_of_Bhutan.svg/540px-Flag_of_Bhutan.svg.png" },
    { country: "Bolivia", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Flag_of_Bolivia.svg/540px-Flag_of_Bolivia.svg.png" },
    { country: "Brazil", url: "https://upload.wikimedia.org/wikipedia/en/thumb/0/05/Flag_of_Brazil.svg/540px-Flag_of_Brazil.svg.png" },
    { country: "Canada", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Canada_%28Pantone%29.svg/540px-Flag_of_Canada_%28Pantone%29.svg.png" },
    { country: "Chile", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Flag_of_Chile.svg/540px-Flag_of_Chile.svg.png" },
    { country: "China", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Flag_of_the_People%27s_Republic_of_China.svg/540px-Flag_of_the_People%27s_Republic_of_China.svg.png" },
    { country: "Colombia", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Flag_of_Colombia.svg/540px-Flag_of_Colombia.svg.png" },
    { country: "Croatia", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Flag_of_Croatia.svg/540px-Flag_of_Croatia.svg.png" },
    { country: "Cuba", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Flag_of_Cuba.svg/540px-Flag_of_Cuba.svg.png" },
    { country: "Costa Rica", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Flag_of_Costa_Rica.svg/540px-Flag_of_Costa_Rica.svg.png" },
    { country: "Czech Republic", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Flag_of_the_Czech_Republic.svg/540px-Flag_of_the_Czech_Republic.svg.png" },
    { country: "Denmark", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Flag_of_Denmark.svg/540px-Flag_of_Denmark.svg.png" },
    { country: "Dominican Republic", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Flag_of_the_Dominican_Republic.svg/540px-Flag_of_the_Dominican_Republic.svg.png" },
    { country: "Egypt", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Flag_of_Egypt.svg/540px-Flag_of_Egypt.svg.png" },
    { country: "Fiji", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Flag_of_Fiji.svg/540px-Flag_of_Fiji.svg.png" },
    { country: "Finland", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Finland.svg/540px-Flag_of_Finland.svg.png" },
    { country: "France", url: "https://upload.wikimedia.org/wikipedia/en/thumb/c/c3/Flag_of_France.svg/540px-Flag_of_France.svg.png" },
    { country: "Germany", url: "https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/540px-Flag_of_Germany.svg.png" },
    { country: "Greece", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Greece.svg/540px-Flag_of_Greece.svg.png" },
    { country: "Hungary", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Flag_of_Hungary.svg/540px-Flag_of_Hungary.svg.png" },
    { country: "Iceland", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Flag_of_Iceland.svg/540px-Flag_of_Iceland.svg.png" },
    { country: "Indonesia", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Flag_of_Indonesia.svg/540px-Flag_of_Indonesia.svg.png" },
    { country: "Iran", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Flag_of_Iran.svg/540px-Flag_of_Iran.svg.png" },
    { country: "Iraq", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Flag_of_Iraq.svg/540px-Flag_of_Iraq.svg.png" },
    { country: "Ireland", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/45/Flag_of_Ireland.svg/540px-Flag_of_Ireland.svg.png" },
    { country: "Israel", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/Flag_of_Israel.svg/540px-Flag_of_Israel.svg.png" },
    { country: "Italy", url: "https://upload.wikimedia.org/wikipedia/en/thumb/0/03/Flag_of_Italy.svg/540px-Flag_of_Italy.svg.png" },
    { country: "Japan", url: "https://upload.wikimedia.org/wikipedia/en/thumb/9/9e/Flag_of_Japan.svg/540px-Flag_of_Japan.svg.png" },
    { country: "Jordon", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Flag_of_Jordan.svg/540px-Flag_of_Jordan.svg.png" },
    { country: "Kenya", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flag_of_Kenya.svg/540px-Flag_of_Kenya.svg.png" },
    { country: "North Korea", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/Flag_of_North_Korea.svg/540px-Flag_of_North_Korea.svg.png" },
    { country: "South Korea", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/Flag_of_South_Korea.svg/540px-Flag_of_South_Korea.svg.png" },
    { country: "Kuwait", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Flag_of_Kuwait.svg/540px-Flag_of_Kuwait.svg.png" },
    { country: "Libya", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Flag_of_Libya.svg/540px-Flag_of_Libya.svg.png" },
    { country: "Malaysia", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Flag_of_Malaysia.svg/540px-Flag_of_Malaysia.svg.png" },
    { country: "Maldives", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Flag_of_Maldives.svg/540px-Flag_of_Maldives.svg.png" },
    { country: "Madagascar", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Madagascar.svg/540px-Flag_of_Madagascar.svg.png" },
    { country: "Mexico", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Flag_of_Mexico.svg/540px-Flag_of_Mexico.svg.png" },
    { country: "Myanmar", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8c/Flag_of_Myanmar.svg/540px-Flag_of_Myanmar.svg.png" },
    { country: "Morocco", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Flag_of_Morocco_%28large_stroke%29.svg/540px-Flag_of_Morocco_%28large_stroke%29.svg.png" },
    { country: "Netherlands", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Flag_of_the_Netherlands.svg/540px-Flag_of_the_Netherlands.svg.png" },
    { country: "New Zealand", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/Flag_of_New_Zealand.svg/540px-Flag_of_New_Zealand.svg.png" },
    { country: "Norway", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Norway.svg/540px-Flag_of_Norway.svg.png" },
    { country: "Palestine", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/Flag_of_Palestine.svg/540px-Flag_of_Palestine.svg.png" },
    { country: "Peru", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Flag_of_Peru.svg/540px-Flag_of_Peru.svg.png" },
    { country: "Philippines", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Flag_of_the_Philippines.svg/540px-Flag_of_the_Philippines.svg.png" },
    { country: "Poland", url: "https://upload.wikimedia.org/wikipedia/en/thumb/1/12/Flag_of_Poland.svg/540px-Flag_of_Poland.svg.png" },
    { country: "Portugal", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Portugal.svg/540px-Flag_of_Portugal.svg.png" },
    { country: "Russia", url: "https://upload.wikimedia.org/wikipedia/en/thumb/f/f3/Flag_of_Russia.svg/540px-Flag_of_Russia.svg.png" },
    { country: "Saudi Arabia", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0d/Flag_of_Saudi_Arabia.svg/540px-Flag_of_Saudi_Arabia.svg.png" },
    { country: "Singapore", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Flag_of_Singapore.svg/540px-Flag_of_Singapore.svg.png" },
    { country: "South Africa", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Flag_of_South_Africa.svg/540px-Flag_of_South_Africa.svg.png" },
    { country: "Sri Lanka", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Flag_of_Sri_Lanka.svg/540px-Flag_of_Sri_Lanka.svg.png" },
    { country: "Sweden", url: "https://upload.wikimedia.org/wikipedia/en/thumb/4/4c/Flag_of_Sweden.svg/540px-Flag_of_Sweden.svg.png" },
    { country: "Thailand", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Flag_of_Thailand.svg/540px-Flag_of_Thailand.svg.png" },
    { country: "Turkey", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Flag_of_Turkey.svg/540px-Flag_of_Turkey.svg.png" },
    { country: "Ukraine", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flag_of_Ukraine.svg/540px-Flag_of_Ukraine.svg.png" },
    { country: "UAE", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Flag_of_the_United_Arab_Emirates.svg/540px-Flag_of_the_United_Arab_Emirates.svg.png" },
    { country: "Uruguay", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Flag_of_Uruguay.svg/540px-Flag_of_Uruguay.svg.png" },
    { country: "Venezuela", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Flag_of_Venezuela.svg/540px-Flag_of_Venezuela.svg.png" },
    { country: "Vietnam", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Flag_of_Vietnam.svg/540px-Flag_of_Vietnam.svg.png" },
    { country: "Zimbabwe", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/Flag_of_Zimbabwe.svg/540px-Flag_of_Zimbabwe.svg.png" },
    { country: "UK", url: "https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/540px-Flag_of_the_United_Kingdom.svg.png" },
    { country: "USA", url: "https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/540px-Flag_of_the_United_States.svg.png" },
    { country: "Pakisthan", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/32/Flag_of_Pakistan.svg/540px-Flag_of_Pakistan.svg.png" },
    { country: "India", url: "https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/540px-Flag_of_India.svg.png" }
  ];


  const countries = flagsArray.map(a => a.country);

  const [score, setScore] = React.useState(0);

  // flagsArray[Math.floor(Math.random() * flagsArray.length)]

  const [currentFlag, setCurrentFlag] = React.useState(flagsArray[Math.floor(Math.random() * flagsArray.length)]);

  const [currentChoice, setCurrentChoice] = React.useState();

  const [choices, setChoices] = React.useState(countries);

  const [timer, setTimer] = React.useState(timeLimit);
  const [qCounter, setQCounter] = React.useState(0);

  const id = React.useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };

  const assessAnswer = (flag, choice) => {
    if(choice === flag.country) {
      setScore(score + 1);
    }
  };

  const handleChoiceSelection = (event) => {
    let answer = event.target.value;
    setCurrentChoice(answer);
  };


  React.useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
    return () => clear();
  }, []);

  React.useEffect(() => {
    if (timer === timeLimit) {

      if (qCounter === maxQ) {
        props.goToCertificate(score, maxQ);
      }
      else {
        setQCounter(qCounter+1);

        let randomFlag = flagsArray[Math.floor(Math.random() * flagsArray.length)];

        setCurrentFlag(randomFlag);
        setCurrentChoice('');

        var choices = [];
        choices[0] = randomFlag.country;
        for (let i = 1; choices.length < 4; i++) {
          var randomChoice = countries[Math.floor(Math.random() * countries.length)];
          if (choices.indexOf(randomChoice) > -1) {
            i = i -1;
          } else {
            choices[i] = randomChoice;
          }
        }

        let len = choices.length,
            currentIndex;
        for (currentIndex = len - 1; currentIndex > 0; currentIndex--) {
            let randIndex = Math.floor(Math.random() * (currentIndex + 1) );
            var temp = choices[currentIndex];
            choices[currentIndex] = choices[randIndex];
            choices[randIndex] = temp;
        }

        setChoices(choices);
      }


    }

    if (timer === 0) {
      assessAnswer(currentFlag, currentChoice);
      setTimer(timeLimit);
    }
  }, [timer]);


	return (
     <div class="container">

        <div class="row">
          <div class="col-sm-12">
            <div class="flag-div">
              <center>
                <img src={currentFlag.url} width="400" class="border border-dark" alt=""/>
              </center>
            </div>
          </div>
         </div>

        <div class="row">
          <br/>
          <br/>
        </div>

        <div class="row container text-center">

          <div class="col-sm-3 form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value={choices[0]} checked={currentChoice === choices[0]} onChange={handleChoiceSelection} />
            <label class="form-check-label" for="inlineRadio1"> {choices[0]} </label>
          </div>

          <div class="col-sm-3 form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value={choices[1]} checked={currentChoice === choices[1]}  onChange={handleChoiceSelection} />
            <label class="form-check-label" for="inlineRadio2"> {choices[1]} </label>
          </div>

          <div class="col-sm-3 form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value={choices[2]} checked={currentChoice === choices[2]}  onChange={handleChoiceSelection} />
            <label class="form-check-label" for="inlineRadio3"> {choices[2]} </label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value={choices[3]} checked={currentChoice === choices[3]}  onChange={handleChoiceSelection} />
            <label class="form-check-label" for="inlineRadio4"> {choices[3]} </label>
          </div>


        </div>

        <div class="row">
          <div class="col text-right text-small">
            <br/>
            <small>
              Question : {qCounter} / {maxQ}
              <br/>
              Time left : {timer}
            </small>
          </div>
        </div>

     </div>
	);
}

export default Quiz;
