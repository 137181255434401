import React from 'react';
import '.././App.css';

function Header() {
	return (
		<div>
	    <center>
	      <h1 class="mb-3 fw-semibold lh-1">
          Flags of the World Challenge
				</h1>
	    </center>
		</div>
	);
}

export default Header;
