import { useLocation, useNavigate } from "react-router-dom";

import Quiz from '.././components/quiz';


const QuizPage = () => {

  const navigate = useNavigate();
  const location = useLocation();

  function goToCertificate(score, maxScore) {
    let certificateInfo = {
      name: location.state.name,
      level: location.state.level,
      score: score,
      maxScore: maxScore
    };
    navigate("/certificate", { state: certificateInfo });
  }

  return (
    <>
      <Quiz goToCertificate={goToCertificate} />
    </>
  );
};

export default QuizPage;
