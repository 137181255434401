import { Outlet } from "react-router-dom";

import Header from '.././components/header';

const Layout = (props) => {
  return (
    <>
      <br/>
      <Header />
      <br/>
      <Outlet />
    </>
  )
};

export default Layout;
