import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import RegisterForm from '.././components/register-form';

const RegisterPage = (props) => {

  // const [registrant, setRegistrant] = React.useState(
  //   {name: 'MS Dhoni', level: 'Normal'}
  // );

  const navigate = useNavigate();
  const location = useLocation();

   let registrant = {
     name: (location.state === undefined || location.state === null) ? 'MS Dhoni' : location.state.name ,
     level: 'Normal'
   };

  function register(regiserData) {
    registrant = regiserData;
  };

  function gotoQuiz() {
    navigate("/quiz", { state: registrant });
  }

  return (
    <>
      <RegisterForm  registrant={registrant} register={register} gotoQuiz={gotoQuiz}/>
    </>
  )
};

export default RegisterPage;
