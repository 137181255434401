import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./pages/layout";
import RegisterPage from "./pages/register-page";
import QuizPage from "./pages/quiz-page";
import CertificatePage from "./pages/certificate-page";

import './index.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<RegisterPage />} />
          <Route path="quiz" element={<QuizPage/>} />
          <Route path="certificate" element={<CertificatePage/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
