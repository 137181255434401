import { useLocation, useNavigate } from "react-router-dom";

import Certificate from '.././components/certificate';


const CertificatePage = () => {

  const navigate = useNavigate();
  const location = useLocation();

  function gotoHome() {
    let registrant = {
      name: location.state.name,
      level: location.state.level
    };
    navigate("/", { state: registrant });
  }

  let certificateProps = {
    competion: 'Flags of the World Challenge',
    name: location.state.name,
    level: location.state.level,
    score: location.state.score,
    maxScore: location.state.maxScore
  };


  return (
    <>
      <Certificate certificateProps={certificateProps} gotoHome={gotoHome} />
    </>
  );
};

export default CertificatePage;
