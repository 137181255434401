import React from 'react';
import { Link } from "react-router-dom";
import html2canvas from 'html2canvas';

function Certificate(props) {

  let certificateProps = props.certificateProps;
  let competion = certificateProps.competion;
  let name = certificateProps.name;
  let score = certificateProps.score;
  let maxScore = certificateProps.maxScore;
  let gotoHome = props.gotoHome;

  let date = new Date();
  let options = { month: 'long', day: 'numeric', year: 'numeric' };
  let dateStr = date.toLocaleDateString(undefined, options);

  let showCertificate = ((100*(score/maxScore)) > 59) ? true : false ;

  function simulateDownloadImageClick(uri, filename) {
    var link = document.createElement('a');
    if (typeof link.download !== 'string') {
      window.open(uri);
    } else {
      link.href = uri;
      link.download = filename;
      accountForFirefox(clickLink, link);
    }
  }

  function clickLink(link) {
    link.click();
  }

  function accountForFirefox(click) { // wrapper function
    let link = arguments[1];
    document.body.appendChild(link);
    click(link);
    document.body.removeChild(link);
  }


  function restartQuiz(event) {
    event.preventDefault();
    gotoHome();
  }


  const handleDownload = (event) => {
    html2canvas(document.querySelector("#certificateView")).then(canvas => {
      // document.body.appendChild(canvas)
      simulateDownloadImageClick(canvas.toDataURL(), 'gudkidz-certificate.png');
    });
  };



  let certificateView = <table class="table table-bordered" id="certificateView">
      <tbody>
        <tr>
          <td>
            <div class="text-center">
              <img src="/gudkidz-logo.png" width="250" alt="" />
            </div>
            <br/>
            <div class="text-center">
              <h3>Certificate of Excellence</h3>
            </div>
            <br/>
            <div class="text-justify">
                <i>
                <p>
                  This is to certify that <strong> {name} </strong> has enthusiastically participated in the online quiz <strong> '{competion}' </strong> on {dateStr}.
                  <br/><br/>
                  {name} demonstrated outstanding knowledge and enthusiasm, scoring <strong> {score} </strong> out <strong> {maxScore} </strong>.
                  We commend {name} for their inquisitive spirit and encourage them to keep exploring and discovering.
                  <br/>
                </p>
                </i>
            </div>
            <br/>
          </td>
        </tr>
      </tbody>
  </table>

  let shareView = <div class="row">
    <div class="col-sm-6">
      <button type="button" class="btn btn-outline-primary" onClick={handleDownload}>
        <i class="bi bi-download"></i> Download your Certificate !
      </button>
    </div>

    {/*
      <div class="col-sm-6 text-right text-muted">
        Share with friends
        &nbsp;&nbsp;&nbsp;
        <i class="bi bi-facebook"></i>
        &nbsp;&nbsp;&nbsp;
        <i class="bi bi-twitter"></i>
        &nbsp;&nbsp;&nbsp;
        <i class="bi bi-whatsapp"></i>
        &nbsp;&nbsp;(coming soon)
      </div>
    */}

  </div>


	return (

    <div class="container">
      <div class="row">
        <div class="col-sm-9">
          { showCertificate ? 'Congradulations ! ' : ''}You have scored {score} out of {maxScore} !! {showCertificate}
        </div>
        <div class="col-sm-3 text-right">
          <Link onClick={restartQuiz}><small>Take the Quiz again</small></Link>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <br/>
            {showCertificate ? certificateView : ''}
          <br/>
        </div>
      </div>

      {showCertificate ? shareView : ''}
      <br/>
      <br/>

    </div>

	);
}

export default Certificate;
